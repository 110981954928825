var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading",
            },
          ],
          ref: "refTable",
          attrs: {
            data: _vm.tableData,
            border: "",
            "header-cell-style": _vm.HEADER_CELL_STYLE,
            "cell-style": _vm.SMALL_CELL_STYLE,
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Logged At", "min-width": "100px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          scope.row.loggedAt
                            ? _vm
                                .$moment(scope.row.loggedAt)
                                .format("YYYY-MM-DD HH:mm:ss")
                            : "_"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              "show-overflow-tooltip": "",
              prop: "username",
              label: "User Name",
              "min-width": "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticStyle: { "white-space": "pre" } }, [
                      _vm._v(
                        _vm._s(
                          scope.row.event &&
                            scope.row.event.includes("DELIVERY_SIGNED")
                            ? _vm.formatUsernameOfDeliverySignedEvent(scope.row)
                            : scope.row.username
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "signerRole",
              label: "Signer Role",
              "min-width": "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "signerName",
              label: "Signer Name",
              "min-width": "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              "show-overflow-tooltip": "",
              prop: "event",
              label: "Event",
              "min-width": "230",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                        },
                      },
                      [
                        _c("div", { staticStyle: { "white-space": "pre" } }, [
                          _vm._v(_vm._s(_vm.formatEvent(scope.row))),
                        ]),
                        scope.row.userPhotoFileId
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "blue",
                                  "text-decoration": "underline",
                                  "margin-top": "-5px",
                                },
                                on: {
                                  click: () =>
                                    _vm.previewFileMixin(
                                      scope.row.userPhotoFileId
                                    ),
                                },
                              },
                              [_vm._v("View Attachment")]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Via Link", "min-width": "60" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(scope.row.viaLink ? "YES" : "NO")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              "show-overflow-tooltip": "",
              prop: "method",
              label: "Method",
              "min-width": "80",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(_vm.formatMethod(scope.row)))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              "show-overflow-tooltip": "",
              prop: "device",
              label: "Client",
              "min-width": "80",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(scope.row.device == null ? "" : scope.row.device)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              "show-overflow-tooltip": "",
              prop: "logData",
              label: "Log Data",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.logData
                      ? _c(
                          "el-popover",
                          { attrs: { placement: "left", trigger: "click" } },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "max-height": "300px",
                                  "overflow-y": "scroll",
                                },
                              },
                              [
                                scope.row.logData
                                  ? _c(
                                      "div",
                                      [
                                        JSON.parse(scope.row.logData).form
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "text-decoration":
                                                    "underline",
                                                },
                                              },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      JSON.parse(
                                                        scope.row.logData
                                                      ).form
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                        JSON.parse(scope.row.logData)
                                          .description
                                          ? _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  JSON.parse(scope.row.logData)
                                                    .description
                                                ) + " "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._l(
                                          JSON.parse(scope.row.logData).data,
                                          function (data, index) {
                                            return _c("div", { key: index }, [
                                              data.orderIndex
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(data.orderIndex) +
                                                        " - "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              data.description
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(data.description) +
                                                        " "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              data.field
                                                ? _c("span", [
                                                    _c("span"),
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(data.field) +
                                                          ": "
                                                      ),
                                                    ]),
                                                    data.oldValue &&
                                                    data.newValue
                                                      ? _c("span", [
                                                          _vm._v("from "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "blue",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.parseValue(
                                                                    data.field,
                                                                    data.oldValue
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" to "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "blue",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.parseValue(
                                                                    data.field,
                                                                    data.newValue
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                      : data.oldValue &&
                                                        !data.newValue
                                                      ? _c("span", [
                                                          _vm._v("from "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "blue",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.parseValue(
                                                                    data.field,
                                                                    data.oldValue
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" to "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "blue",
                                                              },
                                                            },
                                                            [_vm._v("Empty")]
                                                          ),
                                                        ])
                                                      : !data.oldValue &&
                                                        data.newValue
                                                      ? _c("span", [
                                                          _vm._v("from "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "blue",
                                                              },
                                                            },
                                                            [_vm._v("Empty")]
                                                          ),
                                                          _vm._v(" to "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "blue",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.parseValue(
                                                                    data.field,
                                                                    data.newValue
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ])
                                                : _vm._e(),
                                            ])
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _c("el-button", {
                              attrs: {
                                slot: "reference",
                                type: "text",
                                icon: "el-icon-view",
                              },
                              slot: "reference",
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              "show-overflow-tooltip": "",
              label: "Reason",
              "min-width": "80",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticStyle: { "white-space": "pre" } }, [
                      _vm._v(_vm._s(_vm.formatReason(scope.row))),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.tableData.length > 0
        ? _c("el-pagination", {
            attrs: {
              background: "",
              "page-size": _vm.pagination.pageSize,
              "page-sizes": [5, 10, 20, 50, 100],
              "current-page": _vm.pagination.pageNumber,
              layout: "->,total, sizes, prev,  pager, next",
              total: _vm.pagination.total,
            },
            on: {
              "size-change": _vm.handlePageSizeChange,
              "current-change": _vm.handlePageChange,
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.pagination, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.pagination, "pageSize", $event)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }